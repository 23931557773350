<!-- ====
    MODULE REF:
    FILTER
    RETRIEVE DATA
==== -->
<template>
  <v-row>
          <!-- :height="customHeight" -->
          <!-- style="display: grid;" -->
    <v-col :cols="dynamicCols">
      <slot name="dataTable">
        <v-data-table
          fixed-header
          class="css-mobile"
          hide-default-footer
          v-model="model"
          :show-select="showSelect"
          sort-by-text="Urutkan berdasarkan"
          :item-key="itemKey"
          :single-select="singleSelect"
          :headers="headers"
          :items="dataList"
          ref="my-table"
          :page.sync="pagination.data.page"
          :item-class="itemClass"
          :options.sync="options"
          :server-items-length="Number(pagination.data.total_data)"
          :loading="loading"
          loading-text="sedang memuat.."
          :no-data-text="noDataText"
          locale="id"
          :items-per-page="pagination.data.itemsPerPage"
        >
          <template v-for="(slot, name) in $scopedSlots" v-slot:[name]="item">
            <slot :name="name" v-bind="item"></slot>
          </template>

          <!-- FILTER -->
          <template v-if="isFilterOnHeader" v-slot:header="{ props }">
            <v-slide-y-transition style="width: 100%">
              <thead transition="scale-transition" v-if="isFilterVisible">
                <div>
                  <v-dialog
                    v-model="isFilterVisible"
                    scrollable
                    persistent
                    max-width="550px"
                    :fullscreen="$vuetify.breakpoint.xsOnly"
                  >
                    <v-card class="border-radius box-shadow">
                      <v-card-title
                        class="body-2 text-first d-flex mb-4"
                        style="background-color: #d31145;"
                      >
                        <span class="body-1 white--text">
                          <slot name="filterTitle"></slot>
                        </span>
                        <!-- <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              class="ml-2"
                              color="white"
                              v-bind="attrs"
                              v-on="on"
                              icon
                              x-small
                            >
                              <v-icon>mdi-information-outline</v-icon>
                            </v-btn>
                          </template>
                          <span
                            >Gunakan filter untuk menyembunyikan beberapa data
                            dalam tabel <br />untuk sementara waktu, sehingga
                            Anda bisa fokus pada data yang ingin Anda
                            lihat.</span
                          >
                        </v-tooltip> -->
                        <!-- <v-spacer></v-spacer>
                        <v-btn icon dark @click="isFilterVisible = false">
                          <v-icon>mdi-close</v-icon>
                        </v-btn> -->
                      </v-card-title>
                      <v-card-text style="height: 500px;">
                        <v-row
                          class=""
                          v-for="(head, idx) in props.headers"
                          :key="idx"
                        >
                          <!-- HINT 
                          {{ idx }}
                          <v-col>
                          {{ filterHeader[idx] }}
                          </v-col>
                          -->

                          <v-col
                            cols="12"
                            v-if="filterHeader[idx].type == 'custom'"
                          >
                            <slot name="customFilter"></slot>
                          </v-col>

                          <v-col v-if="filterHeader[idx].type != ''">
                            <v-autocomplete
                              v-if="
                                filterHeader[idx].type == 'autocomplete' &&
                                  head.value == filterHeader[idx].column
                              "
                              :label="filterHeader[idx].label"
                              :value="generateFilterValue(idx)"
                              @change="
                                (newVal) => listenerFilterChange(newVal, idx)
                              "
                              :items="filterHeader[idx].items"
                              :item-text="
                                filterHeader[idx].itemText
                                  ? filterHeader[idx].itemText
                                  : 'name'
                              "
                              :item-value="
                                filterHeader[idx].itemValue
                                  ? filterHeader[idx].itemValue
                                  : 'id'
                              "
                              outlined
                              color="#d31145"
                              dense
                            ></v-autocomplete>

                            <v-text-field
                              v-if="
                                filterHeader[idx].type == 'text' &&
                                  head.value == filterHeader[idx].column
                              "
                              v-model="
                                filterModel[filterHeader[idx].column].value
                              "
                              :label="filterHeader[idx].label"
                              outlined
                              color="#d31145"
                              dense
                              :hint="filterHeader[idx].hint"
                            ></v-text-field>

                            <VueCtkDateTimePicker
                              v-if="
                                filterHeader[idx].type == 'date_range' &&
                                  head.value == filterHeader[idx].column
                              "
                              v-model="
                                filterModel[filterHeader[idx].columnFilter]
                                  .value
                              "
                              label="Pilih Tanggal"
                              :range="true"
                              :ref="`dialog-date-picker-range${idx}`"
                              noClearButton
                              color="#d31145"
                              format="YYYY-MM-DD"
                              formatted="LL"
                              locale="id"
                              :max-date="todays"
                              :custom-shortcuts="[
                                { key: 'today', label: 'Hari ini', value: 'day' },
                                { key: 'thisWeek', label: 'Minggu ini', value: 'isoWeek' },
                                { key: 'lastWeek', label: 'Minggu lalu', value: '-isoWeek' },
                                { key: 'last7Days', label: '7 hari terakhir', value: 7 },
                                { key: 'last30Days', label: '30 hari terakhir', value: 30 },
                                { key: 'thisMonth', label: 'Bulan ini', value: 'month' },
                                { key: 'lastMonth', label: 'Bulan lalu', value: '-month' },
                                { key: 'thisYear', label: 'Tahun ini', value: 'year' },
                                { key: 'lastYear', label: 'Tahun lalu', value: '-year' },
                              ]"
                            />

                            <v-select
                              :item-text="
                                filterHeader[idx].itemText
                                  ? filterHeader[idx].itemText
                                  : 'name'
                              "
                              :item-value="
                                filterHeader[idx].itemValue
                                  ? filterHeader[idx].itemValue
                                  : 'id'
                              "
                              v-if="
                                filterHeader[idx].type == 'select' &&
                                  head.value == filterHeader[idx].column
                              "
                              :items="filterHeader[idx].items"
                              v-model="
                                filterModel[filterHeader[idx].column].value
                              "
                              :label="filterHeader[idx].label"
                              dense
                              outlined
                              color="#d31145"
                            ></v-select>
                            <div
                              class="text-center"
                              v-if="filterHeader[idx].type == 'opsi'"
                            ></div>
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <v-card-actions class="text-right">
                        <v-spacer></v-spacer>
                        <v-btn
                          :loading="loading"
                          :disabled="loading"
                          elevation="0"
                          color="#d31145"
                          class="ma-1 white--text"
                          @click="doFilter"
                          small
                        >
                          Filter
                        </v-btn>
                        <v-btn
                          text
                          color="red"
                          :loading="loading"
                          :disabled="loading"
                          elevation="0"
                          class="ma-2 white--text"
                          @click="resetFilterForm"
                          small
                        >
                          Reset
                        </v-btn>
                        <v-btn
                          elevation="0"
                          class="ma-2 "
                          small
                          @click="isFilterVisible = false"
                        >
                          Tutup
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </div>
              </thead>
            </v-slide-y-transition>
          </template>
          <!-- END FILTER -->

          <!-- FOOTER -->
          <template v-slot:[`footer`]>
            <div v-show="pagination.data.total_page > 0">
              <v-divider
                class="mt-2"
                style=" border: thin solid rgba(0,0,0,.12);"
              ></v-divider>

              <!-- ==== CUSTOM FOOTER WITH CHANGEABLE PAGE ==== -->
              <v-card-text v-if="$vuetify.breakpoint.name !== 'xs'">
                <v-row class="justify-center" align="center">
                  <v-col
                    cols="12"
                    class="d-flex align-center pa-0 mt-1 justify-end"
                  >
                    <!-- ==== LIMIT DATA ==== -->
                    <div
                      class="mr-4 ml-5 "
                      style="font-size:0.83rem; color:#7b7b7b;"
                    >
                      Baris per halaman
                    </div>
                    <div>
                      <v-select
                        dense
                        :items="limit_options"
                        item-text="label"
                        item-value="label"
                        color="#7b7b7b"
                        hide-details
                        v-model="limit"
                        style="width: 60px; font-size:0.83rem; color:#7b7b7b;"
                      >
                      </v-select>
                    </div>

                    <div
                      style="font-size:0.83rem; color:#7b7b7b;"
                      class="mt-2 mr-4 ml-5"
                    >
                      {{ (pagination.data.current - 1) * limit + 1 }} -
                      {{
                        (pagination.data.current - 1) * limit +
                          1 +
                          pagination.data.total_display -
                          1
                      }}
                      dari {{ pagination.data.total_data }} Data

                      <!-- ==== refresh data ==== -->
                      <v-btn @click="retrieveData()" icon class="ml-2">
                        <v-icon>mdi-refresh</v-icon>
                      </v-btn>
                    </div>

                    <v-btn
                      color="#7b7b7b"
                      elevation="0"
                      height="30"
                      small
                      @click="page = 1"
                      :disabled="page < 2 || isNaN(page)"
                      icon
                      class="mt-2 text-capitalize"
                    >
                      <v-icon> mdi-page-first</v-icon>
                    </v-btn>

                    <v-btn
                      color="#7b7b7b"
                      elevation="0"
                      height="30"
                      small
                      icon
                      :disabled="page < 2 || isNaN(page)"
                      class="mt-2  "
                      @click="page = page - 1"
                    >
                      <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>

                    <div class="mx-4 ">
                      <v-text-field
                        dense
                        hide-details
                        v-model="page"
                        :disabled="pagination.data.total_page == 1"
                        min="1"
                        style="width: 50px; font-size:0.83rem;"
                        id="halaman"
                        type="number"
                        color="#7b7b7b"
                      >
                      </v-text-field>
                    </div>

                    <v-btn
                      color="#7b7b7b"
                      elevation="0"
                      height="30"
                      small
                      icon
                      :disabled="
                        page === pagination.data.total_page || isNaN(page)
                      "
                      class="mt-2 text-capitalize"
                      @click="page = page + 1"
                    >
                      <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                    <v-btn
                      color="#7b7b7b"
                      elevation="0"
                      height="30"
                      small
                      icon
                      :disabled="
                        page === pagination.data.total_page || isNaN(page)
                      "
                      @click="page = pagination.data.total_page"
                      class="mt-2 text-capitalize"
                    >
                      <v-icon> mdi-page-last</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>


              <v-card-text v-if="$vuetify.breakpoint.name === 'xs'">
                <v-row jclass="justify-start" align="center">
                  <v-col
                    cols="8"
                    class="d-flex align-center pa-1 mt-1 justify-start"
                  >
                    <!-- ==== LIMIT DATA ==== -->
                    <!-- <div
                      class="mr-4 ml-5 "
                      style="font-size:10px; color:#7b7b7b;"
                    >
                      Baris per halaman
                    </div> -->
                    <div style="font-size:11px;">
                      {{ (pagination.data.current - 1) * limit + 1 }} -
                      {{
                        (pagination.data.current - 1) * limit +
                          1 +
                          pagination.data.total_display -
                          1
                      }}
                      dari {{ pagination.data.total_data }} Data
                    </div>
                  </v-col>

                  <v-col
                    cols="4"
                    class="d-flex align-center pa-0 mt-1 justify-end"
                  >
                    <v-btn @click="retrieveData()" icon class="ml-2">
                      <v-icon size="18">mdi-refresh</v-icon>
                    </v-btn>
                  </v-col>

                  <v-col
                    cols="4"
                    class="d-flex align-center pa-1 mt-1 justify-start"
                  >
                    <!-- ==== LIMIT DATA ==== -->
                    <!-- <div
                      class="mr-4 ml-5 "
                      style="font-size:10px; color:#7b7b7b;"
                    >
                      Baris per halaman
                    </div> -->
                    <div>
                      <v-select
                        dense
                        :items="limit_options"
                        item-text="label"
                        item-value="label"
                        color="#7b7b7b"
                        hide-details
                        v-model="limit"
                        style="width: 60px; font-size:11px; color:#7b7b7b;"
                      >
                      </v-select>
                    </div>
                  </v-col>

                  <v-col
                    cols="8"
                    class="d-flex align-center pa-0 mt-1 justify-end"
                  >
                    <v-btn
                      color="#7b7b7b"
                      elevation="0"
                      height="30"
                      small
                      @click="page = 1"
                      :disabled="page < 2 || isNaN(page)"
                      icon
                      class="mt-2 text-capitalize"
                    >
                      <v-icon> mdi-page-first</v-icon>
                    </v-btn>

                    <v-btn
                      color="#7b7b7b"
                      elevation="0"
                      height="30"
                      small
                      icon
                      :disabled="page < 2 || isNaN(page)"
                      class="mt-2  "
                      @click="page = page - 1"
                    >
                      <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>

                    <div class="mx-4 ">
                      <v-text-field
                        dense
                        hide-details
                        v-model="page"
                        :disabled="pagination.data.total_page == 1"
                        min="1"
                        style="width: 50px; font-size:11px;"
                        id="halaman"
                        type="number"
                        color="#7b7b7b"
                      >
                      </v-text-field>
                    </div>

                    <v-btn
                      color="#7b7b7b"
                      elevation="0"
                      height="30"
                      small
                      icon
                      :disabled="
                        page === pagination.data.total_page || isNaN(page)
                      "
                      class="mt-2 text-capitalize"
                      @click="page = page + 1"
                    >
                      <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                    <v-btn
                      color="#7b7b7b"
                      elevation="0"
                      height="30"
                      small
                      icon
                      :disabled="
                        page === pagination.data.total_page || isNaN(page)
                      "
                      @click="page = pagination.data.total_page"
                      class="mt-2 text-capitalize"
                    >
                      <v-icon> mdi-page-last</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
              <!-- <v-divider
                class="mt-2"
                style=" border: thin solid rgba(0,0,0,.12);"
              ></v-divider> -->
              <!-- ==== END CUSTOM FOOTER WITH CHANGEABLE PAGE ==== -->
            </div>
          </template>
          <!-- END FOOTER -->
        </v-data-table>
      </slot>
    </v-col>
  </v-row>
</template>

<script>
import moment from "moment";
import Pagination from "@/components/Pagination";

export default {
  props: {
    isTitle: {
      type: Boolean,
      required: false,
      default: true,
    },
    isLoadAgain: {
      type: Boolean,
      required: false,
      default: false,
    },
    filterTitle: {
      type: String,
      required: false,
      default: "Filter Data",
    },
    isHeader: {
      type: Boolean,
      required: false,
      default: true,
    },
    customHeight: {
      type: String,
      required: false,
      default: "calc(100vh - 504px)",
    },
    scssId: {
      type: String,
      required: false,
      default: "",
    },
    classContainer: {
      type: String,
      required: false,
      default: "",
    },
    textTitle: {
      type: String,
      required: false,
      default: "",
    },
    noDataText: {
      type: String,
      required: false,
      default: "data tidak ditemukan.",
    },
    showSelect: {
      type: Boolean,
      required: false,
      default: false,
    },
    singleSelect: {
      type: Boolean,
      required: false,
      default: false,
    },
    itemKey: {
      type: String,
      required: false,
      default: "",
    },
    headers: {
      type: Array,
      required: false,
      default: () => [],
    },
    itemClass: {
      type: String,
      required: false,
      default: "",
    },
    /**
     * FILTER
     */
    isExternalFilterButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    isFilterOnHeader: {
      type: Boolean,
      required: false,
      default: false,
    },
    dateType: {
      type: String,
      required: false,
      default: "",
    },
    dateFormat: {
      type: String,
      required: false,
      default: "",
    },
    filterType: {
      type: String,
      required: false,
      default: "",
    },

    /**
     * Supported type: empty (""), text, autocomplete, date_range, opsi, select, slot
     */
    filterHeader: {
      type: Array,
      required: false,
      default: () => [
        {
          type: "",
          column: "", //column header
          columnFilter: "", //column name as docs, required date_range
          valueDefault: "", // date_range []
          label: "",
          items: [], //select [], autocomplete[{}]
          dataType: "", // overwrite check datatype |text, number
          custom: "",
          hint: "",
        },
      ],
    },

    /**
     * FILTER END
     */
    /**
     * RETRIEVE DATA
     */
    retrieveDataURL: {
      type: String,
      required: true,
      default: "",
    },
    defaultSort: {
      type: String,
      required: false,
      default: "",
    },
    disablePagination: {
      type: Boolean,
      required: false,
      default: false,
    },
    /**
     * RETRIEVE DATA END
     */
  },
  components: {
    Pagination,
  },
  emits: [
    "onMounted",
    "onRetrieveDataStart",
    "onRetrieveDataEnd",
    "onLoadSuccess",
    "onGetDataModel",
  ],
  data: () => ({
    /**
     * DATA CUSTOM FILTER PARAMS
     */
    todays: new Date().toISOString().substr(0, 10),
    product_id: "",
    start_date: "",
    end_date: "",
    /**
     * DATA CUSTOM FILTER PARAMS
     */

    /**
     * CUSTOM FOOTER DATA
     */
    page: 1,
    limit: 10,
    total_page: null,
    current: 1,
    searchVal: "",
    limit_options: [
      {
        label: 10,
      },
      {
        label: 20,
      },
      {
        label: 50,
      },
      {
        label: 100,
      },
    ],
    /**
     * END CUSTOM FOOTER DATA
     */

    model: [],
    dynamicCols: 12,
    options: {},
    /**
     * FILTER
     */
    filterModel: {},
    isFilterVisible: false,

    //datepicker custom
    value2: [],
    firstDay: "",
    lastDay: "",
    date1: "",
    date2: "",
    showTimeRangePanel: false,
    //end datepicker custom

    /**
     * FILTER END
     */
    /**
     * RETRIEVE DATA
     */
    disableRetrieveData: false,
    loading: false,
    params: {},
    dataList: [],
    pagination: {
      data: {},
      page: "",
    },

    /**
     * RETRIEVE DATA END
     */
  }),
  computed: {
    mobileCard() {
      if (this.$vuetify.breakpoint.mobile) {
        return true;
      }
      return this.$vuetify.breakpoint.mobile;
    },
    Pagination() {
      if (this.$attrs.model.page === 1) {
        this.page = 1;
      }
      return this.$attrs.model;
    },
    filtered() {
      return this.value2
        ? moment(this.value2[0]).format("DD MMMM YYYY") +
            " - " +
            moment(this.value2[1]).format("DD MMMM YYYY")
        : "";
    },
    filterdateStart() {
      return this.value2[0]
        ? moment(this.value2[0]).format("YYYY-MM-DD")
        : "";
    },
    filterdateEnd() {
      return this.value2[1]
        ? moment(this.value2[1]).format("YYYY-MM-DD")
        : "";
    },
  },
  methods: {
    // inputPage(val) {
    //   if (isNaN(val)) {
    //   }
    // },

    /**
     * FILTER
     */
    toTopPage() {
      var table = this.$refs['my-table'];
      var wrapper = table.$el.querySelector('div.v-data-table__wrapper');
      
      // this.$vuetify.goTo(table); // to table
      this.$vuetify.goTo(table, {container: wrapper}); // to header
    },
    formatDate(date) {
      if (!date || date.length <= 0) return null;
      let str = "";
      date.forEach((element, index) => {
        const [year, month, day] = element.split("-");
        str += `${month}/${day}/${year}`;
        if (date.length == 2 && index == 0) {
          str += " ~ ";
        }
      });
      return str;
    },
    generateFilterValue(idx) {
      return this.filterHeader[idx].columnFilter
        ? this.filterModel[this.filterHeader[idx].columnFilter].value
        : this.filterModel[this.filterHeader[idx].column].value;
    },
    listenerFilterChange(newVal, idx) {
      this.filterHeader[idx].columnFilter
        ? (this.filterModel[this.filterHeader[idx].columnFilter].value = newVal)
        : (this.filterModel[this.filterHeader[idx].column].value = newVal);
    },
    generateFilterModel() {
      let filterModel = {};
      this.filterHeader.forEach((element) => {
        if (element.columnFilter) {
          filterModel[element.columnFilter] = {
            value: element.valueDefault,
            valueDefault: element.valueDefault,
            type: element.type,
            dialog: false,
            dataType: element.dataType ? element.dataType : "",
          };
        } else {
          filterModel[element.column] = {
            value: element.valueDefault,
            valueDefault: element.valueDefault,
            type: element.type,
            dialog: false,
            dataType: element.dataType ? element.dataType : "",
          };
        }
      });
      this.filterModel = filterModel;
    },
    doFilter() {
      this.retrieveData();
      // console.log(this.$route.path)
      if (Object.keys(this.filterModel).length > 1) {
        if (this.$route.path === '/purchase/list' || this.$route.path === '/client/list' || this.$route.path === '/cashier' || this.$route.path === '/report-debt/list') {
          let que = {
            filter: JSON.stringify(this.filterModel),
            page: this.params.page,
            limit: this.params.limit,
            url: this.$route.path
          }
          // console.log('asd')
          
          // this.$router.replace({ path: `${this.$route.path}?filter=${JSON.stringify(this.filterModel)}` })
          // this.$router.replace({ query: que}).catch(()=>{})
          this.$router.replace({ path: '404' , query: que})
          .then(() => console.log('Navigated!'))
          .catch(() => {})
        } else {
          this.$router.replace({ path: `${this.$route.path}?page=${this.params.page}&limit=${this.params.limit}&filter=${JSON.stringify(this.filterModel)}` })
        }
      }
      this.isFilterVisible = false;
    },
    resetFilterForm() {
      this.$router.replace({ path: `${this.$route.path}` })
      this.$emit("onResetFilter");
      for (const key in this.filterModel) {
        if (Object.hasOwnProperty.call(this.filterModel, key)) {
          const element = this.filterModel[key];

          // this.filterModel[key].type = "";
          this.filterModel[key].value = element.valueDefault;
        }
      }
      for (const key in this.params) {
        if (Object.hasOwnProperty.call(this.params, key)) {
          if (key.includes("filter[")) {
            delete this.params[key];
          }
        }
      }
      this.retrieveData();
      this.isFilterVisible = false;
    },
    /**
     * FILTER END
     */
    /**
     * RETRIEVE DATA
     */
    async retrieveData() {
      this.$emit("onRetrieveDataStart");
      this.loading = true;
      let sign =
        this.options.sortDesc && this.options.sortDesc[0] ? "DESC" : "ASC";
      let params = this.params;
      params.page = this.page;
      params.limit = this.limit;
      params.search = this.searchVal;

      if (this.options.sortBy.length > 0 && this.options.sortDesc.length > 0) {
        params.sort = this.options.sortBy && this.options.sortBy[0];
        params.dir = sign;
      } else {
        params.sort = this.defaultSort;
        params.dir = this.defaultDir;
      }

      // check if query parameters page in limit not undefined
      if (this.$route.query.page && this.$route.query.limit) {
        let page = parseInt(this.$route.query.page)
        let limit = parseInt(this.$route.query.limit)

        params = {page, limit, ...params}
      }

      /**
       * FILTER
       */
      // console.log(this.params)
      if (this.filterModel) {
        let count = 0;
        // console.log(this.filterModel)
        // console.log(JSON.stringify(this.filterModel))
        if (this.$route.query.filter !== undefined) {
          this.$router.replace({ path: `${this.$route.path}?page=${params.page}&limit=${params.limit}&filter=${JSON.stringify(this.filterModel)}` })
          // console.log(this.$route.path)
        }
        for (const key in this.filterModel) {
          if (Object.hasOwnProperty.call(this.filterModel, key)) {
            const element = this.filterModel[key];
            if (element.value != "" && element.value != null) {
              // if (this.$route.path === '/cashier') {
              //   let comparison = "=";
              //   let value = "";
              //   if (element.type == "date_range") {
              //     if (this.dateType == "date") {
              //       value = `${moment(element.value[0]).format(
              //         "YYYY-MM-DD"
              //       )}::${moment(element.value[1]).format("YYYY-MM-DD")}`;
              //     } else if (this.dateType == "datetime") {
              //       value = `${moment(element.value[0]).format(
              //         "YYYY-MM-DD"
              //       )}::${moment(element.value[1]).format(
              //         "YYYY-MM-DD"
              //       )}`;
              //     }
              //     // else {
              //     // value = `${moment(element.value[0]).format(
              //     //   "YYYY-MM-DD"
              //     // )}::${moment(element.value[1]).format("YYYY-MM-DD")}`;
              //     params[`filter[${count}][type]`] = "date";
              //     params[`filter[${count}][field]`] = key;
              //     params[`filter[${count}][value]`] = value;
              //     params[`filter[${count}][comparison]`] = comparison;
              //     // }
              //   } else {
              //     let isNotNumber = !!isNaN(element.value);
              //     //overwirte data type check
              //     if (element.dataType) {
              //       if (element.dataType == "number") {
              //         isNotNumber = false;
              //       } else if (element.dataType == "text") {
              //         isNotNumber = true;
              //       }
              //     }
              //     if (isNotNumber) {
              //       params[`filter[${count}][type]`] = "string";
              //       params[`filter[${count}][field]`] = key;
              //       params[`filter[${count}][value]`] = element.value;
              //       params[`filter[${count}][comparison]`] = "==";
              //     } else {
              //       params[`filter[${count}][type]`] = "numeric";
              //       params[`filter[${count}][field]`] = key;
              //       params[`filter[${count}][value]`] = element.value;
              //       params[`filter[${count}][comparison]`] = "=";
              //     }
              //   }
              //   count++;
              // }
              if (this.filterType != "laporan") {
                let comparison = "=";
                let value = "";
                if (element.type == "date_range") {
                  if (this.dateType == "date") {
                    value = `${moment(element.value[0]).format(
                      "YYYY-MM-DD"
                    )}::${moment(element.value[1]).format("YYYY-MM-DD")}`;
                  } else if (this.dateType == "datetime") {
                    value = `${moment(element.value[0]).format(
                      "YYYY-MM-DD"
                    )}::${moment(element.value[1]).format(
                      "YYYY-MM-DD"
                    )}`;
                  }
                  // else {
                  // value = `${moment(element.value[0]).format(
                  //   "YYYY-MM-DD"
                  // )}::${moment(element.value[1]).format("YYYY-MM-DD")}`;
                  params[`filter[${count}][type]`] = "date";
                  params[`filter[${count}][field]`] = key;
                  params[`filter[${count}][value]`] = value;
                  params[`filter[${count}][comparison]`] = this.filterType == "date_between" ? "bet" : comparison;
                  // }
                } else {
                  let isNotNumber = !!isNaN(element.value);
                  //overwirte data type check
                  if (element.dataType) {
                    if (element.dataType == "number") {
                      isNotNumber = false;
                    } else if (element.dataType == "text") {
                      isNotNumber = true;
                    }
                  }
                  if (isNotNumber) {
                    params[`filter[${count}][type]`] = "string";
                    params[`filter[${count}][field]`] = key;
                    params[`filter[${count}][value]`] = element.value;
                    params[`filter[${count}][comparison]`] = "==";
                  } else {
                    params[`filter[${count}][type]`] = "numeric";
                    params[`filter[${count}][field]`] = key;
                    params[`filter[${count}][value]`] = element.value;
                    params[`filter[${count}][comparison]`] = "=";
                  }
                }
                count++;
              }
            }

          }
        }
      }

      if (this.params) {
        if (this.$route.query.filter !== undefined) {
          this.$router.replace({ path: `${this.$route.path}?page=${params.page}&limit=${params.limit}&filter=${JSON.stringify(this.filterModel)}` })
          // console.log(this.$route.path)
        }else {
          if (this.$route.path === '/purchase/list' || this.$route.path === '/client/list' || this.$route.path === '/cashier' || this.$route.path === '/report-debt/list') {
            // akan di lemparkan ke halaman 404
            let que = {
              filter: JSON.stringify(this.filterModel),
              page: params.page,
              limit: params.limit,
              url: this.$route.path
            }

            this.$router.replace({ path: '404' , query: que})
            .then(() => console.log('Navigated!'))
            .catch(() => {})
          } else {
            this.$router.replace({ path: `${this.$route.path}?page=${params.page}&limit=${params.limit}` })
          }
        }
      }

      try {
        // console.log(params)
        const response = await this.$get(this.retrieveDataURL, { params });
        let res = response.data
        // console.log(res);
        if (res.status === 200) {
          if (!this.disablePagination) {
            if (
              res.results.pagination.current >
                res.results.pagination.total_page &&
              res.results.pagination.total_data > 0
            ) {
              this.options.page = this.options.page - 1;
              this.retrieveData();
            }
            this.pagination.data = res.results.pagination;
          }

          this.dataList = res.results.data;
          setTimeout(() =>{
            this.toTopPage()
          }, 100)
        } else if (res.data.status === 400) {
          // console.log(res);
        }
        this.$emit("onRetrieveDataEnd", res.results);
      } catch (error) {
        this.$emit("onRetrieveDataEnd", error);
        // console.log(error);
        if (error.response) {
          let errorData = error.response.data;
          let errorCode = error.response.status;
          let errorHeader = error.response.headers;

          if (errorCode === 401) {
            this.$store.state.unauthorization = true;
            this.$store.state.loading = false;
          }
        } else {
          // this.$store.state.errorNetwork = true;
          this.$store.state.loading = false;
        }
      }
      this.loading = false;
    },
    resetList() {
      this.dataList = [];
      this.dataPagination = {
        total_data: 0,
        page: 1,
        itemsPerPage: 10,
      };
    },
    /**
     * RETRIEVE DATA END
     */
    disabledAfterThisDay(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      return (date > today);
    },
    getDataModel() {
      this.$emit("onGetDataModel", this.model);
      return this.model;
    },
  },
  mounted() {
    this.$emit("onMounted");
    this.generateFilterModel();

    if (this.$route.query.filter !== undefined) {
      this.filterModel = JSON.parse(this.$route.query.filter)
      this.retrieveData();
      this.isFilterVisible = false;
      for (const key in this.filterModel) {
        if (Object.hasOwnProperty.call(this.filterModel, key)) {
          const element = this.filterModel[key];
          if (element.type === 'date_range') {
            let date = []
            // console.log('dtanggal', new Date(element.value))
            for (let index = 0; index < element.value.length; index++) {
              // date = new Date(element.value[index])
              date.push(new Date(element.value[index]))
              // console.log(new Date(element.value[index]))
            }
            element.value = date
          }
        }
      }
      // console.log(this.$route.path)
    }

    if (this.$route.query.page) {
      this.page = parseInt(this.$route.query.page)
    }

    if (this.$route.query.limit) {
      this.limit = parseInt(this.$route.query.limit)
    }

    /**
     * datepicker
     */
    let firstDay = new Date(
      new Date().setDate(new Date().getDate() - new Date().getDay())
    );
    let lastDay = new Date(
      new Date().setDate(new Date().getDate() - new Date().getDay() + 6)
    );
    const filter = [firstDay, lastDay];
    this.value2 = filter;
  },
  watch: {
    isLoadAgain: {
      handler(newVal) {
        // console.log('isLoadAgain', newVal);
        if (newVal === true) {
          this.retrieveData();
        }
      },
      immediate: true,
    },
    options: {
      handler() {
        if (this.retrieveData !== null && !this.disableRetrieveData) {
          this.retrieveData();
        }
      },
      deep: true,
    },
    dataList: {
      immediate: true,
      handler: function(newVal) {
        if (newVal.length > 0) {
          this.$emit("onLoadSuccess");
        }
      },
    },
    /**
     * Watch change value of data limit
     */
    limit() {
      //SET PAGE TO 1
      this.page = this.$route.query.page ? parseInt(this.$route.query.page) : 1;
      //RE-FETCH DATA
      this.retrieveData();
    },

    value2: function(val) {
      // this.retrieveData();
    },

    page: function(newVal) {
      //GET NEWVAL AND PARSE IT TO INTEGER
      let parseNewVal = parseInt(newVal);
      //HANDLE LIMIT NUMBER PAGE
      if (parseNewVal > this.pagination.data.total_page || parseNewVal < 1) {
        setTimeout(() => {
          parseNewVal = this.$route.query.page ? parseInt(this.$route.query.page) : 1;
          this.page = 1;
        }, 10);
      }
      //IF ITS IS NOT A NUMBER RE-FETCH DATA
      if (!isNaN(this.page)) {
        this.retrieveData();
      }
    },
    model : function(val) {
      this.getDataModel()
      // this.$emit("onGetDataModel", val);
    }
  },
};
</script>

<style lang="scss">
.v-window {
  overflow: inherit !important;
}
//  kalau header di fix atas di comment
</style>



<style>
  .css-mobile.v-data-table--fixed-header thead th {
    box-shadow: unset !important;
    color: #9e9e9e !important;
    background: #f8f8f9!important;
  }

  /* table {
    position: relative;
  }
  th {
    background: white;
    position: sticky;
    overflow: hidden !important;
    top: 0; 
    box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
  } */
</style>
